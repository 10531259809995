<!-- Component: Variations (Shades & Tints) Palette -->

<script>

    // Check if the color is dark or not
    const isDarkColour = (color) => {
        var color = (color.charAt(0) === '#') ? color.substring(1, 7) : color;
        var r = parseInt(color.substring(0, 2), 16); // hexToR
        var g = parseInt(color.substring(2, 4), 16); // hexToG
        var b = parseInt(color.substring(4, 6), 16); // hexToB
        return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ? false : true;
    }

    // Props
    export let data;

</script>

<!-- Render Colour Shades & Tints -->
<div class="coloralias-variations" class:coloralias-variations--active="{ data.colours.length > 0 && data.panels.variations }">

    {#each data.colours as { spectrum }}
        <div class="coloralias-variations__row">

            {#each spectrum as item}
                <span class="coloralias-variations__block" style="background-color:{item.hex}">
                    <span class="coloralias-variations__label" class:coloralias-variations__label--dark="{ isDarkColour(item.hex) }">
                        { item.hex }
                    </span>
                </span>
            {/each}

        </div>
    {/each}

</div>

<style lang="scss">
    @import "../assets/scss/components/colour-variations-palette.scss";
</style>