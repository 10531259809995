<script>
    // Import Svelte's fade transition
    import { fade } from 'svelte/transition';
    
    // Import colour names codex and code to find the nearest colour
    import colourCodex from './assets/colour-codex.min.js';
    import nearestColor from './assets/nearest-colour.min.js';

    // Import SVG icons sourced from https://teenyicons.com
    import Logo from './assets/svgs/logo.svelte';
    import RemoveIcon from './assets/svgs/icon--remove.svelte';
    import InfoIcon from './assets/svgs/icon--info.svelte';
    import CloseIcon from './assets/svgs/icon--close.svelte';
    import LinkIcon from './assets/svgs/icon--link.svelte';

    // Components
    import InfoText from './components/info.svelte';
    import PrivacyPolicy from './components/privacy-policy.svelte';
    import About from './components/about.svelte';
    import List from './components/list.svelte';
    import VariationsList from './components/list-variations.svelte';
    import VariationsPalette from './components/variations-palette.svelte';
    import Notice from './components/notice.svelte';

    // ###########################

    // App Legend
    // ==========

    /**
     * A label with @ at the end, indicates it's an object.
 
     * 1. App data @
     * 2. [methods] Methods @
     * -- 2a. [utils] Utilities
     * ---- 2a-1. [hexTest] Test if the hex code is valid
     * ---- 2a-2. [string2slug] Convert string to a slug
     * -- 2b. [notice] Notices @
     * ---- 2b-1. [show] Show notice
     * ---- 2b-2. [hide] Hide notice
     * -- 2c. [colours] Colours @
     * ---- 2c-1. [addData] Add additional data to the colour object
     * ---- 2c-2. [create] Create an object containing the colour's data and push it into the 'data.colours' array
     * ---- 2c-3. [parse] Parse a string of hex codes, create colour objects and add push them into the 'data.colours' array
     * ---- 2c-4. [remove] Remove colour from 'data.colours' array, therefore from the lists and grid
     * ---- 2c-5. [sort] Sort and refresh the 'data.colours' array
     * ---- 2c-6. [shade] Shade the rgb color object to a distance
     * ---- 2c-7. [tint] Tint the rgb color object to a distance
     * -- 2d. [copy] Copy elements to the clipboard @
     * ---- 2d-1. [list] Copy list data to the clipboard
     * ---- 2d-2. [url] Copy URL to clipboard
     * -- 2e. [url] URL @
     * ---- 2e-1. [load] Load colour data based on URL parameters
     * ---- 2e-2. Update the URL with hex code parameters
     * -- 2f. [processEntry] Process the hex colour codes given in the input field
     * -- 2g. [toggleInfo] Toggle the information box
     * -- 2h. [variationsToggle] Toggle between the base colors and variations views
     * -- 2i. [modalsToggle] Add class to the <body> tag and prevent scrolling when a modal is active
     * 3. When the window is loaded
     * 4. Prevent page loading to last scrolled location, always make it at the top of the page
     */

    // ###########################
    
    // 1. App data
    // ===========

	let data = {
        codex: Object.assign({}, ...Object.entries(colourCodex).map(([a,b]) => ({ [b]: a }))), // Create an object of rearranged data from the colour names codex
        colours: [],
        currentYear: new Date().getFullYear(),
        sortBy: 'name',
        info: {
            visible: true,
            localStorage: localStorage.getItem('infoShown'),
            labels: {
                active: 'Information',
                hide: 'Close'
            }
        },
        lists: {
            labels: {
                scss_map: 'SCSS Map',
                css_vars: 'CSS Variables',
                php_array: 'PHP Array',
                object: 'Object'
            }
        },
        notice: {
            visible: false,
            status: '',
            message: '',
            duration: 3000 // 3 seconds
        },
        panels: {
            privacyPolicy: false,
            about: false,
            variations: false
        },
        url: {
            params: new URLSearchParams(window.location.search),
            hasHParam: false
        }
    };

    // Detect if there's a 'h' parameter in the URL
    data.url.hasHParam = data.url.params.has("h") ? true : false;

    // Determine to whether display the information panel on page load
    data.info.visible = data.url.hasHParam || data.info.localStorage  ? false : true;

    const hashedSupportEmail = "6605090a0914070a0f0715261614091209080b070f0a4805090b";

    // ###########################

    // 2. Methods
    // ==========

    const methods = {

        // 2a. Utilities
        // -------------
        utils: {

            // 2a-1. Test if the hex code is valid
            hexTest: (hex) => {
                return /^[0-9A-F]{6}$/i.test(hex);
            },

            // 2a-2. Convert string to a slug
            string2slug: (str) => {
                if(str){
                    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
                    str = str.replace(/^\s+|\s+$/g, '');
                    str = str.toLowerCase();
                    str = str.replace('.', '-')
                        .replace(/[^a-z0-9 -]/g, '')
                        .replace(/\s+/g, '-')
                        .replace(/-+/g, '-');
            
                    return str;
                }
            },

            // 2a-3. Pad a hexadecimal string with zeros if it needs it
            pad: (number, length) => {
                let str = '' + number;
                while (str.length < length) {
                    str = '0' + str;
                }
                return str;
            },

            // 2a-4.
            intToHex: (rgbint) =>  {
                return methods.utils.pad(Math.min(Math.max(Math.round(rgbint), 0), 255).toString(16), 2);
            },

            // 2a-5.
            rgbToHex: (rgb) => {
                return '#' + methods.utils.intToHex(rgb.red) + methods.utils.intToHex(rgb.green) + methods.utils.intToHex(rgb.blue);
            }
        },

        // 2b. Notices
        // -------------
        notice: {

            // 2b-1. Show notice
            show: (status, message) => {
                data.notice.visible = true;
                data.notice.status = status;
                data.notice.message = message;
            },

            // 2b-2. Hide notice
            hide: () => {
                data.notice.visible = false;
                data.notice.status = '';
                data.notice.message = '';
            },
        },

        // 2c. Colours
        // -----------
        colours: {

            // 2c-1. Add additional data to the colour object
            addData: (obj) => {
                const hex = obj.hex.substring(1);

                const rgb = {
                    red: parseInt(hex.substring(0, 2), 16),
                    green: parseInt(hex.substring(2, 4), 16),
                    blue: parseInt(hex.substring(4, 6), 16)
                }

                // Get the RGB values to calculate the hue
                let r = rgb.red / 255;
                let g = rgb.green / 255;
                let b = rgb.blue / 255;
                
                // Getting the Max and Min values for chroma
                let max = Math.max.apply(Math, [r, g, b]);
                let min = Math.min.apply(Math, [r, g, b]);
                
                // Variables for HSV value of hex colour
                let chr = max - min;
                let hue = 0;
                let val = max;
                let sat = 0;

                if (val > 0) {
                    // Calculate saturation only if value isn't 0
                    sat = chr / val;
                    if (sat > 0) {
                        if (r == max) {
                            hue = 60 * (((g - min) - (b - min)) / chr);
                            if (hue < 0) {
                                hue += 360;
                            }
                        } 
                        else if (g == max) {
                            hue = 120 + 60 * (((b - min) - (r - min)) / chr);
                        } 
                        else if (b == max) {
                            hue = 240 + 60 * (((r - min) - (g - min)) / chr);
                        }
                    }
                }

                let shades = [];
                let tints = [];
                for (var i = 0; i < 4; i++) {
                    shades.push( methods.colours.shade(rgb, i, obj.slug) );
                    tints.push( methods.colours.tint(rgb, i, obj.slug) );
                }

                obj.chroma = chr;
                obj.hue = hue;
                obj.sat = sat;
                obj.val = val;
                obj.luma = 0.3 * r + 0.59 * g + 0.11 * b;
                obj.rgb = rgb;
                obj.tints = tints.filter(e => e.hex !== obj.hex).reverse();
                obj.shades = shades.filter(e => e.hex !== obj.hex);
                obj.spectrum = [...obj.tints, {slug: obj.slug, hex: obj.hex} , ...obj.shades];
                return obj;
            },

            // 2c-2. Create an object containing the colour's data and push it into the 'data.colours' array
            create: (id, hexCode) => {
                let hex = hexCode.charAt(0) === '#' ? hexCode.replace("#", "") : hexCode,
                    nearest = nearestColor.from(data.codex);

                // Convert hex code to lowercase
                hex = hex.toLowerCase();

                // If 3 are characters given
                if( hex.length === 3 ){
                    hex = hex + hex;
                }

                if( data.colours.find(col => col.hex === '#' + hex) ){
                    return false;
                }

                if( methods.utils.hexTest(hex) ){
                    const hexCode = '#' + hex,
                        matchedColour = nearest(hex);

                    // Hide the notice
                    methods.notice.hide();

                    // Create the colour's object and push into the 'data.colours' array
                    if(matchedColour.name){
                        let obj = {};
                        obj.id = id;
                        obj.name = matchedColour.name;
                        obj.slug = methods.utils.string2slug(matchedColour.name);
                        obj.hex = hexCode;
                        obj.hexChars = hex;
                        obj = methods.colours.addData(obj);
                        data.colours.push(obj);
                    }

                    // Sort when adding the colours
                    switch (data.sortBy) {
                        case 'hex':
                            data.colours = data.colours.sort((a, b) => a.hex.localeCompare(b.hex));
                            break;

                        case 'name':
                            data.colours = data.colours.sort((a, b) => a.name.localeCompare(b.name));
                            break;

                        case 'hue':
                            data.colours = data.colours.sort((a, b) => a.hue - b.hue);
                            break;
                        
                        case 'lum':
                            data.colours = data.colours.sort((a, b) => a.val - b.val);
                            break;
                    }
                }
                else{

                    // Show notice if invalid hex codes are present
                    methods.notice.show('error', 'Requires 3 or 6 valid hex characters (excluding #).');

                    // Hide the notice after a specifc amount of time
                    setTimeout( () => {
                        methods.notice.hide();
                    }, data.notice.duration);
                }
            },

            // 2c-3. Parse a string of hex codes, create colour objects and add push them into the 'data.colours' array
            parse: (string) => {
                let hexes;

                // Convert string into lowercase
                string = string.toLowerCase();

                if( string.includes(",") ){
                    hexes = string.replace(/\s/g, "").split(",");

                    for(let i = 0; i < hexes.length; i++){
                        methods.colours.create(i, hexes[i]);
                    }
                }
                else if(string.includes(" ") && !string.includes(",") ){
                    hexes = string.split(" ");
                    
                    for(let i = 0; i < hexes.length; i++){
                        methods.colours.create(i, hexes[i]);
                    }
                }
                else{
                    methods.colours.create(0, string);
                }

            },

            // 2c-4. Remove colour from 'data.colours' array, therefore from the lists and grid
            remove: (ev) => {
                const button = ev.target.parentNode,
                    hex = button.getAttribute('data-hex');

                data.colours = data.colours.filter((col) => { return col.hex != hex; });
                data.colours = data.colours;

                // Update the URL to reflect the changes
                methods.url.update();
            },

            // 2c-5. Sort and refresh the 'data.colours' array
            sort: () => {
                let sorted;

                switch (data.sortBy) {
                    case 'hex':
                        sorted = data.colours.sort((a, b) => a.hex.localeCompare(b.hex));
                        break;

                    case 'name':
                        sorted = data.colours.sort((a, b) => a.name.localeCompare(b.name));
                        break;

                    case 'hue':
                        sorted = data.colours.sort((a, b) => a.hue - b.hue);
                        break;
                    
                    case 'lum':
                        sorted = data.colours.sort((a, b) => a.val - b.val);
                        break;
                }

                data.colours = sorted;
            },

            // 2c-6. Shade the rgb color object to a distance
            shade: (rgb, i, slug) => {
                const distance = 0.25; // 25%

                const newRGB = {
                    red: rgb.red * (1 - distance * i),
                    green: rgb.green * (1 - distance * i),
                    blue: rgb.blue * (1 - distance * i)
                }

                return{
                    slug: `${slug}-shade-${i}`,
                    hex: methods.utils.rgbToHex(newRGB)
                }

                //return methods.utils.rgbToHex(newRGB);
            },

            // 2c-7. Tint the rgb color object to a distance
            tint: (rgb, i, slug) => {
                const distance = 0.25; // 25%
                
                const newRGB = {
                    red: rgb.red + (255 - rgb.red) * i * distance,
                    green: rgb.green + (255 - rgb.green) * i * distance,
                    blue: rgb.blue + (255 - rgb.blue) * i * distance
                }

                return{
                    slug: `${slug}-tint-${i}`,
                    hex: methods.utils.rgbToHex(newRGB)
                }
                //return methods.utils.rgbToHex(newRGB);
            }
        },

        // 2d. Copy elements to the clipboard
        // ----------------------------------
        copy: {

            // 2d-1. Copy list data to the clipboard
            list: (type, listType) => {
                let label;
                const listElement = document.querySelector(`.coloralias-list--${listType}[data-type="${type}"] .coloralias-list__inner`);

                data.notice.visible = false;

                switch (type) {
                    case 'scss_map':
                        label = data.lists.labels.scss_map;
                        break;
                    case 'css_vars':
                        label = data.lists.labels.css_vars;
                        break;
                    case 'php_array':
                        label = data.lists.labels.php_array;
                        break;
                    case 'object':
                        label = data.lists.labels.object;
                        break;
                }
                
                try {
                    if (!navigator.clipboard) return; // For unsupported browsers

                    let el = document.createElement('textarea'); // Create a temp textarea to copy from
                    el.value = listElement.innerText; // Add selected text into temp textarea
                    el.setAttribute('readonly', '');
                    el.style.position = 'absolute';
                    el.style.left = '-9999px';
                    document.body.appendChild(el);

                    navigator.clipboard.writeText(el.value) // Copy text in the temp textarea
                    .then(() => {
                        document.body.removeChild(el); // Remove the temp textarea
                        methods.notice.show("success", `'${label}' now copied!`); // Notice - Success
                    })
                    .catch(() => {
                        methods.notice.show("error", `Unable to copy '${label}'!`);
                    });
                } 
                catch(err) {
                    methods.notice.show("error", `Unable to copy '${label}'!`); // Notice -  Error
                }
                
                // Hide the notice after a specifc amount of time
                setTimeout( () => {
                    methods.notice.hide();
                }, data.notice.duration);
                
            },

            // 2d-2. Copy URL to clipboard
            url: () => {
                if (!navigator.clipboard) return; // For unsupported browsers

                const url = window.location.href;
                
                navigator.clipboard.writeText(url) // Copy the URL
                .then(() => {
                    methods.notice.show("success", "URL Copied!"); // Notice - Success
                })
                .catch(() => {
                    methods.notice.show("error", "Unable to copy the URL!"); // Notice - Error
                });

                // Hide the notice after a specifc amount of time
                setTimeout( () => {
                    methods.notice.hide();
                }, data.notice.duration);
            },
        },

        // 2e. URL
        // -------
        url: {

            // 2e-1. Load colour data based on URL parameters
            load: () => {
                // If URL has a 'h' parameter.. check hexes are valid, parse and add to the 'data.colours' array
                if( data.url.hasHParam ){
                    const hParam = data.url.params.get("h");
                    const hexArray = hParam.split('-'); // Converts the string into an array, so we can check the hex codes
                    const hasValidHexes = hexArray.every(methods.utils.hexTest); // Checks if all hex codes are valid from the parameter

                    // Parse the input data and add it to the 'data.colours' array
                    if(hasValidHexes){
                        const converted = hParam.replaceAll("-", ",");
                        methods.colours.parse(converted);
                    }
                    else{
                        // Display error notice
                        methods.notice.show("error", "Oops! There's invalid hex codes in the URL query.");
                    }
                }
            },

            // 2e-2. Update the URL with hex code parameters
            update: () => {
                if( data.colours.length > 0 ){
                    const hexChars = data.colours.map(({ hexChars }) => hexChars).join("-");
                    window.history.pushState( {} , '', `?h=${hexChars}` );
                }
                else{
                    window.history.pushState(null, null, window.location.pathname);
                }
            }
        },

        // 2f. Process the hex colour codes given in the input field
        // --------------------------------------------------------
        processEntry: () => {
            const input = document.getElementById('colour-entry');
            
            data.colours = [];

            // Parse the input data and add it to storage
            methods.colours.parse(input.value);

            // Clear the input field
            input.value = "";

            // Add hex code URL parameters
            methods.url.update();

            // Hide the Info panel
            methods.infoToggle(true);
        },

        // 2g. Toggle the information box
        // ------------------------------
        infoToggle: (hideInfo) => {
            const button = document.querySelector('.coloralias-info__button'),
                buttonSpan = button.querySelector('span'),
                buttonPath = button.querySelector('path');
            
            // Remember the visitor has seen the app'ss information
            if(!data.info.localStorage){
                localStorage.setItem('infoShown', true)
            }

            // Hide the information panel
            if(hideInfo === true){

                // Indicate to not display the information
                data.info.visible = false;

                // Display the appropriate button text
                buttonSpan.textContent = data.info.labels.active;

                // Remove classes
                button.classList.remove('active');
                buttonPath.classList.remove('active');
                buttonSpan.classList.remove('active');
            }
            else{
                // Toggle the information visibility
                data.info.visible = !data.info.visible;

                // Toggle classes
                button.classList.toggle('active');
                buttonPath.classList.toggle('active');
                buttonSpan.classList.toggle('active');

                // Determine which button text to display
                buttonSpan.textContent = !data.info.visible ? data.info.labels.active : data.info.labels.hide;
            }
        },

        // 2h. Toggle between the base colors and variations views
        variationsToggle: () => {
            data.panels.variations = !data.panels.variations;
        },

        // 2i. Add class to the <body> tag and prevent scrolling when a modal is active
        modalsToggle: (bool) => {
            const body = document.body;
            const bodyClasslist = body.classList;
            if(bool){
                bodyClasslist.add('modal-is-active');
                body.style.overflow = "hidden";
                document.querySelector('html').style.overflow = "hidden";
            }
            else{
                bodyClasslist.remove('modal-is-active');
                body.style.overflow = "";
                document.querySelector('html').style.overflow = "";
            }
        }

    };

    // ###########################

    // 3. When the window is loaded
    // ============================

    window.onload = () => {
        
        // Load the page data via URL parameters
        methods.url.load();

        // Listen for key events used in the input field
        document.getElementById('colour-entry').addEventListener('keyup', function (ev) {
            ev.preventDefault();
            
            // Add colours when the 'Enter' button is pressed
            if (ev.key === 'Enter') {
                methods.processEntry();
            }
        });
        
        // Add a class to #coloralias element
        document.getElementById('coloralias').classList.add('coloralias--loaded');
        
    }

    // ###########################

    // 4. Prevent page loading to last scrolled location, always make it at the top of the page
    // ========================================================================================

    if (history.scrollRestoration) {
        history.scrollRestoration = 'manual';
    } 
    else {
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        }
    }

</script>

<!-- Render -->

<div class="app-wrapper" class:app-wrapper--modal-active={ data.panels.about || data.panels.privacyPolicy }>

    <!-- Notices -->
    {#if data.notice.visible}
        <Notice data={ data.notice } />
    {/if}

    <!-- Site Header -->
    <header class="app-header" class:app-header--grid-active="{ data.colours.length > 0 }">
        <h1>Color Alias</h1>
        <figure class="app-header__figure">
            <Logo/>
        </figure>
    </header>

    <main class="app-main">
        
        <div class="coloralias-upper" class:coloralias-upper--grid-active="{ data.colours.length > 0 }">

            <!-- Entry (aka input) -->
            <div class="coloralias-hex">
                <input id="colour-entry" class="input input--text coloralias-hex__entry" placeholder="Add your hex codes here">

                <button 
                class="button button--primary coloralias-hex__submit" 
                on:click={ methods.processEntry }
                >
                    Go
                </button>
            </div>

            <div class="coloralias-options">
               
                <!-- Sort by -->
                <div class="coloralias-sortby">
                    <span class="coloralias-sortby__text">Sort by:</span>

                    <!-- Sort by: Name -->
                    <label class="coloralias-sortby__label coloralias-sortby__label--name">
                        <input 
                        value={'name'} 
                        class="coloralias-sortby__radio coloralias-sortby__radio--name" 
                        type="radio" 
                        bind:group={ data.sortBy } 
                        on:change={ methods.colours.sort } 
                        />
                        <span>Name</span>
                    </label>

                     <!-- Sort by: Hex -->
                    <label class="coloralias-sortby__label coloralias-sortby__label--hex">
                        <input 
                        value={'hex'} 
                        class="coloralias-sortby__radio coloralias-sortby__radio--hex" 
                        type="radio" 
                        bind:group={ data.sortBy } 
                        on:change={ methods.colours.sort } 
                        />
                        <span>Hex</span>
                    </label>

                     <!-- Sort by: Hue -->
                    <label class="coloralias-sortby__label coloralias-sortby__label--hue">
                        <input 
                        value={'hue'} 
                        class="coloralias-sortby__radio coloralias-sortby__radio--hue" 
                        type="radio" 
                        bind:group={ data.sortBy } 
                        on:change={ methods.colours.sort } 
                        />
                        <span>Hue</span>
                    </label>

                     <!-- Sort by: Luminosity -->
                    <label class="coloralias-sortby__label coloralias-sortby__label--lum">
                        <input 
                        value={'lum'} 
                        class="coloralias-sortby__radio coloralias-sortby__radio--lum" 
                        type="radio" 
                        bind:group={ data.sortBy } 
                        on:change={ methods.colours.sort } 
                        />
                        <span>Luminosity</span>
                    </label>

                </div>

                <div class="coloralias-info">

                    <!-- Copy URL button -->
                    {#if data.colours.length > 0}
                        <div class="coloralias-info__copy-url">

                            <button 
                            title="Copy URL" 
                            aria-label="Copy URL"
                            class="coloralias-info__copy-url-button" 
                            on:click={ methods.copy.url } 
                            >
                                <figure>
                                    <LinkIcon />
                                </figure>
                                <span>Copy URL</span>
                            </button>

                        </div>
                    {/if}

                    <!-- Information: Toggle Button -->
                    <button 
                    aria-label="{data.info.labels.active}" 
                    title="{data.info.labels.active}" 
                    class="coloralias-info__button" 
                    class:active="{ data.info.visible }" 
                    on:click={methods.infoToggle}
                    >

                        <figure>
                            {#if data.info.visible}
                                <CloseIcon /> 
                            {:else}
                                <InfoIcon />
                            {/if}
                        </figure>

                        <span class:active="{ data.info.visible }">
                            { data.info.visible ? data.info.labels.hide : data.info.labels.active }
                        </span>

                    </button>

                    <!-- Information: Panel -->
                    {#if data.info.visible}
                        <div class="coloralias-info__text" transition:fade="{{ duration: 250 }}">
                            <InfoText />    
                        </div>
                    {/if}

                </div>

            </div>

        </div>

        <!-- Colour Grid -->
        <div class="coloralias-grid" class:coloralias-grid--active="{ data.colours.length > 0 }">

            {#each data.colours as { id, hex, name, slug }}

                <span 
                title={ name } 
                class="coloralias-grid__item coloralias-grid__item--{ id } 
                coloralias-grid__item--{slug}" 
                data-hex="{ hex }" 
                style="background-color: { hex };"
                >

                    <p class="coloralias-grid__title">
                        { name }
                    </p>

                    <button 
                    class="coloralias-grid__remove" 
                    title="Remove { name }" 
                    data-hex={ hex } 
                    on:click={ methods.colours.remove }
                    >
                        <RemoveIcon />
                    </button>

                </span>

            {/each}

        </div>

        <!-- Variations Tuggle Button -->
        <div class="coloralias-middle" class:coloralias-middle--active="{ data.colours.length > 0 }">

            <button 
            aria-label={ !data.panels.variations ? 'View Shades & Tints' : 'View Base Colors' } 
            class="button button--secondary button--variations" 
            on:click={ methods.variationsToggle }
            >
                View { !data.panels.variations ? 'Shades & Tints' : 'Base Colors' }
            </button>

        </div>

        <!-- Colour Shades & Tints -->
        <VariationsPalette data={ data } />

        <!-- Colour Lists: Base -->
        <div class="coloralias-lists coloralias-lists--base" class:coloralias-lists--active="{ data.colours.length > 0 && !data.panels.variations }">

            <!-- List: SCSS -->
            <List
            colors={ data.colours }
            methods={ methods }
            labels={ data.lists.labels.scss_map }
            listSlug={'scss_map'}
            startCloseChars= { ['&#40', '&#41;'] } 
            />

            <!-- List: CSS variables -->
            <List
            colors={ data.colours }
            methods={ methods }
            labels={ data.lists.labels.css_vars }
            listSlug={'css_vars'}
            startCloseChars= { [] } 
            />

            <!-- List: PHP Array -->
            <List
            colors={ data.colours }
            methods={ methods }
            labels={ data.lists.labels.php_array }
            listSlug={'php_array'}
            startCloseChars= { ['&#91', '&#93;'] } 
            />

            <!-- List: Object -->
            <List
            colors={ data.colours }
            methods={ methods }
            labels={ data.lists.labels.object }
            listSlug={'object'}
            startCloseChars= { ['&#123', '&#125;'] } 
            />
            
        </div>

        <!-- Colour Lists: Shades and Tint -->
        <div class="coloralias-lists coloralias-lists--variations" class:coloralias-lists--active="{ data.colours.length > 0 && data.panels.variations }">

            <!-- List: SCSS -->
            <VariationsList
            colors={ data.colours }
            methods={ methods }
            labels={ data.lists.labels.scss_map }
            listSlug={'scss_map'}
            startCloseChars= { ['&#40', '&#41;'] } 
            />

            <!-- List: CSS variables -->
            <VariationsList
            colors={ data.colours }
            methods={ methods }
            labels={ data.lists.labels.css_vars }
            listSlug={'css_vars'}
            startCloseChars= { [] } 
            />

            <!-- List: PHP Array -->
            <VariationsList
            colors={ data.colours }
            methods={ methods }
            labels={ data.lists.labels.php_array }
            listSlug={'php_array'}
            startCloseChars= { ['&#91', '&#93;'] } 
            />

            <!-- List: Object -->
            <VariationsList
            colors={ data.colours }
            methods={ methods }
            labels={ data.lists.labels.object }
            listSlug={'object'}
            startCloseChars= { ['&#123', '&#125;'] } 
            />
            
        </div>

    </main>

    <!-- Footer -->
    <footer class="app-footer">
        <div class="app-footer__inner">

            <span class="app-footer__text">
                <a href="https://www.coloralias.com" rel="noopener" target="_blank">Color Alias</a> by <a href="https://madebygrant.com" rel="noopener" target="_blank">Grant Deelstra</a>. 
                <span class="app-footer__copyright">&copy; { data.currentYear } All Rights Reserved. </span>
            </span>

            <div class="app-footer__buttons">

                <button 
                class="panel-toggle panel-toggle--about" 
                on:click={ () => { 
                    data.panels.about = !data.panels.about; 
                    methods.modalsToggle(data.panels.about)
                } }
                >
                    About
                </button>

                <button 
                class="panel-toggle panel-toggle--privacy-policy" 
                on:click={ () => { 
                    data.panels.privacyPolicy = !data.panels.privacyPolicy; 
                    methods.modalsToggle(data.panels.privacyPolicy) 
                } }
                >
                    Privacy Policy
                </button>

            </div>

        </div>
    </footer>

    <!-- Panel: About -->
    {#if data.panels.about}
        
        <div 
        class="panel panel--about" 
        class:panel--active="{data.panels.about}" 
        transition:fade="{{ duration: 500 }}" 
        on:click|self={ () => { data.panels.about = false; methods.modalsToggle(false) } }
        >
            <div class="panel__inner">
                
                <button 
                class="panel__close"
                title="Close" 
                aria-label="Close the Contact modal"
                on:click={ () => { 
                    data.panels.about = !data.panels.about; 
                    methods.modalsToggle(data.panels.about) 
                } } 
                >
                    <CloseIcon />
                </button>

                <About hashedEmail={ hashedSupportEmail } />

            </div>
        </div>

    {/if}

    <!-- Panel: Privacy Policy -->
    {#if data.panels.privacyPolicy}

        <div 
        class="panel panel--privacy" 
        class:panel--active="{data.panels.privacyPolicy}" 
        transition:fade="{{ duration: 500 }}" 
        on:click|self={ () => { data.panels.privacyPolicy = false; methods.modalsToggle(false) } }
        >
            <div class="panel__inner">
                
                <button 
                class="panel__close"
                title="Close" 
                aria-label="Close the Privacy Policy modal"
                on:click={ () => { 
                    data.panels.privacyPolicy = !data.panels.privacyPolicy; 
                    methods.modalsToggle(data.panels.privacyPolicy) 
                } } 
                >
                    <CloseIcon />
                </button>

                <PrivacyPolicy />

            </div>
        </div>

    {/if}

</div>

<!-- App's styles -->
<style type="text/scss" lang="scss">
   @import "./assets/scss/app.scss";
</style>