<!-- Component: List - Variations (Shades and Tints) -->

<script>

    // Copy icon
    import CopyIcon from '../assets/svgs/icon--clipboard-add.svelte';

    // Decode HTML Entities
    const decodeHTMLEntities = (entity) => {
        let ta = document.createElement('textarea');
        ta.innerHTML = entity;
        return ta.value;
    }

    // Props
    export let colors;
    export let labels;
	export let listSlug;
    export let methods;
    export let startCloseChars;

    const chars = {
        'start': '',
        'end': ''
    }

    // Start and End Characters
    if(chars){
        chars.start = startCloseChars[0] ? decodeHTMLEntities(startCloseChars[0]) : "",
        chars.end = startCloseChars[1] ? decodeHTMLEntities(startCloseChars[1]) : ""
    }

</script>

<!-- Render -->

<div class="coloralias-list coloralias-list--variations" data-type={ listSlug }>

    <p class="coloralias-list__title">
        { labels }
        <button 
        aria-label="Copy" 
        title="Copy" 
        class="coloralias-list__copy" 
        on:click={ () => methods.copy.list(listSlug, 'variations') }
        >
            <span>Copy</span>
            <CopyIcon />
        </button>
    </p>

    <p class="coloralias-list__inner">
        { chars.start }

        {#if listSlug === 'css_vars'}

            {#each colors as item}
                <span class="coloralias-list__group">

                    {#each item.spectrum as {slug, hex}}
                        <span>--col-{ slug }: { hex };</span>
                    {/each}

                </span>
            {/each}

        {:else if listSlug === 'php_array' }

            {#each colors as item}
                <span class="coloralias-list__group">

                    {#each item.spectrum as {slug, hex}, i}
                        <span>
                            "{ slug }" => "{ hex }"{#if i+1 !== colors.length},{/if}
                        </span>
                    {/each}

                </span>
            {/each}

        {:else }

            {#each colors as item}
                <span class="coloralias-list__group">

                    {#each item.spectrum as {slug, hex}, i}
                        <span>"{ slug }": "{ hex }"{#if i+1 !== colors.length},{/if}</span>
                    {/each}

                </span>
            {/each}

        {/if}

        { chars.end }
    </p>

</div>

<style lang="scss">
    @import "../assets/scss/components/colour-list.scss";
</style>