<!-- Component: Information -->

<p class="intro">
    <span class="spiel">Have you ever been given a bunch of hex color codes and need to name them?</span>
    This tool will help you generate names to use in your project.
</p>

<p>
    You can add single or multiple hex codes.<br />
    Each hex code given, the hash is optional however must contain 3 or 6 valid characters.<br/>
</p>

<p>
    For multiple hex codes, they can be either in a comma separated list or separated by spaces.<br />
    <span class="coloralias-info__example">
        <strong class="examples-heading">Examples:</strong><br />
        <span class="highlight">333, f76c5e, #555, 3a6ea5, #004e98</span>
        <span class="highlight">444 #023e8a fcd5ce eee #b7b7a4</span>
    </span>
</p>

<style type="text/scss" lang="scss">
   @import "../assets/scss/components/info.scss";
</style>