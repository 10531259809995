<!-- Component: About-->

<script>
    import { onMount } from 'svelte';

    // Props
    export let hashedEmail;

    /**
     * @preserve: Obscure Email - Hashed, v1.1
     * @url: https://github.com/madebygrant/obsmail
     * @author: Grant, https://madebygrant.com
     */

    const obsMail = {
        _d: function(encodedString){
            let string = "",
                keyInHex = encodedString.substr(0, 2),
                key = parseInt(keyInHex, 16);
        
            for ( let n = 2; n < encodedString.length; n += 2 ) {
                let charInHex = encodedString.substr(n, 2),
                    char = parseInt(charInHex, 16),
                    output = char ^ key;
        
                string += String.fromCharCode(output);
            }
            return string;
        },

        hashed: function(){
            const emailLinks = document.getElementsByClassName("obs-mail");

            if (0 !== emailLinks.length){

                for (let i = 0; i < emailLinks.length; ++i) {
                    let emailA = emailLinks[i].getAttribute("data-link"),
                        updated_email = this._d(emailA),
                        label = emailLinks[i].querySelector("span");

                    if( label && label.innerHTML == '' ){
                        label.textContent = updated_email;
                    }

                    emailLinks[i].addEventListener("click", function(e) {
                        e.preventDefault();

                        let emailA2 = updated_email,
                            emailS = e.target.parentNode.getAttribute("data-subject");

                        if (emailS != 'undefined' && emailS != null) {
                            window.location.href = "mailto:" + emailA2 + "?subject=" + emailS
                        } 
                        else {
                            window.location.href = "mailto:" + emailA2
                        }

                    })
                }

            }
        }
    }

    onMount(async () => {
        obsMail.hashed();
    });
    
</script>

<!-- Render -->

<h2 class="panel-title">About</h2>

<p>
    Hi, my name is <a href="https://madebygrant.com" rel="noopener" target="_blank">Grant</a>!
    I developed Color Alias as a personal project and decided to share it with others that may find it useful.
</p>

<p>
    If you'd like to send me some feedback or suggestions, you can email me at: <a class="obs-mail" data-link="{ hashedEmail }" rel="noopener noreferrer" target="_blank"><span style="color:inherit;"></span></a>
</p>


<!-- Component's styles -->
<style lang="scss">
    @import "../assets/scss/typography.scss";
</style>