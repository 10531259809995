<!-- Component: Notices -->

<script>

    // Props
    export let data;

    // Icons
    import InfoFillIcon from '../assets/svgs/icon--yellow-fill-info.svelte';
    import TickFillIcon from '../assets/svgs/icon--green-fill-tick.svelte';
    import ExclamationFillIcon from '../assets/svgs/icon--red-fill-exclamation.svelte';

</script>

<!-- Render -->

<span class="coloralias-notice coloralias-notice--{ data.status }">

    <span class="coloralias-notice__icon">
        <figure>
            {#if data.status == 'success'}
                <TickFillIcon /> 
            {:else if data.status == 'error'}
                <ExclamationFillIcon /> 
            {:else if data.status == 'info'}
                <InfoFillIcon />
            {/if}
        </figure>
    </span>

    { data.message }
    
</span>

<style lang="scss">
    @import "../assets/scss/components/notice.scss";
</style>